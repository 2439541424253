import React, { useState, useEffect } from "react"
import Layout from "../components/Layout"
import { graphql, Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import { BLOCKS, MARKS } from "@contentful/rich-text-types"
import Button from "../components/Button"
import SEO from "../components/SEO"

const Resource = ({ data }) => {
  const [email, setEmail] = useState("")
  const [firstName, setFirstName] = useState("")
  const [loader, setLoader] = useState(false)
  const [lastName, setLastName] = useState("")
  const [thankYouMessage, setThankYouMessage] = useState("")

  data = data.contentfulResource

  const handleSubmit = async event => {
    event.preventDefault() // Prevent the form from redirecting
    setLoader(true)
    const formData = new FormData(event.target) // Get form data

    // Submit the form data via fetch to Mailchimp
    const response = await fetch(
      "https://mooremomentum.us20.list-manage.com/subscribe/post",
      {
        method: "POST",
        body: formData,
        mode: "no-cors", // Mailchimp might require this to avoid CORS issues
      }
    )

    // After successful submission, trigger the file download
    if (response.ok || response.type === "opaque") {
      // Handle no-cors responses or successful submissions
      const link = document.createElement("a")
      link.href = `${data?.fileName}` // Path to the file
      link.download = `${data?.fileName}`.replace("/", "") // File name for the download
      link.click()

      setThankYouMessage(
        `Thank you for your submission, ${firstName}! Your file is downloading now.`
      )
      setLoader(false)
    } else {
      console.error("Error submitting form")
      setLoader(false)
    }
  }

  return (
    <Layout absoluteHeader>
      <SEO
        description={data.metaDescription}
        title={data.title}
        image={data.image.url}
        link={`https://mooremomentum.com/resource/${data.slug}/`}
      />
      <div className="primary-gradient-bg pb-0 py-32 md:pt-40 lg:pt-36">
        <div className="container mx-auto px-4 relative text-white z-10">
          <div className="">
            <div className="space-y-4 text-link">
              <h1 className="md:text-3xl text-xl text-white font-Orbitron mb-12">
                {data?.title}
              </h1>
              <figure className="mb-8 flex justify-center rounded-lg">
                <GatsbyImage
                  image={data.image.gatsbyImageData}
                  alt={data.image?.file?.fileName}
                  className="rounded-lg equation"
                  objectFit="contain"
                  loading="eager"
                  style={{ width: "40%" }}
                />
              </figure>
            </div>
          </div>
        </div>
      </div>
      <div className="container mx-auto py-10 px-4">
        <div className="py-8 space-y-4 blogitem ">
          {/* @ts-ignore */}
          {renderRichText(data.description, {
            renderMark: {
              [MARKS.BOLD]: text => <span className="font-bold">{text}</span>,
              [MARKS.ITALIC]: text => <em>{text}</em>,
            },
            renderNode: {
              [BLOCKS.PARAGRAPH]: (node, children) => (
                <p className="text-link">{children}</p>
              ),
              [BLOCKS.HEADING_1]: (node, children) => (
                <h1 className="font-Orbitron text-2xl">{children}</h1>
              ),
              [BLOCKS.HEADING_2]: (node, children) => (
                <h2
                  className="font-Orbitron text-xl"
                  id={
                    children[0]
                      ? children[0].props?.children
                          .replaceAll(" ", "-")
                          .replaceAll(/\u00a0/g, "-")
                      : children
                          ?.replaceAll(" ", "-")
                          .replaceAll(/\u00a0/g, "-")
                  }
                >
                  {children}
                </h2>
              ),
              [BLOCKS.HEADING_3]: (node, children) => (
                <>
                  {" "}
                  {console.log(children)}
                  <h3
                    className="font-Orbitron text-lg"
                    id={
                      children[0]
                        ? children[0].props?.children
                            .replaceAll(" ", "-")
                            .replaceAll(/\u00a0/g, "-")
                        : children
                        ? children[0]
                            .replaceAll(" ", "-")
                            .replaceAll(/\u00a0/g, "-")
                        : children
                            .replaceAll(" ", "-")
                            .replaceAll(/\u00a0/g, "-")
                    }
                  >
                    {children}
                  </h3>
                </>
              ),
              [BLOCKS.EMBEDDED_ASSET]: node => {
                if (!node.data.target) {
                  return
                }
                // console.log("Imagess",node.data.target.gatsbyImageData)
                const { gatsbyImageData } = node.data.target
                if (!gatsbyImageData) {
                  // asset is not an image
                  return null
                }
                return (
                  <figure className="flex flex-wrap justify-center items-center">
                    {node.data.target.file.fileName ===
                    "button press start to begin BLACK VERSION (1) (1).gif" ? (
                      <a href="/core-values-quiz/" target="_blank">
                        <GatsbyImage image={gatsbyImageData} alt="Blog Image" />
                      </a>
                    ) : node.data.target.file.fileName === "contact.gif" ? (
                      <a href="/life-coach-for-young-adults/" target="_blank">
                        <GatsbyImage image={gatsbyImageData} alt="Blog Image" />
                      </a>
                    ) : (
                      <GatsbyImage
                        image={gatsbyImageData}
                        alt="Blog Image"
                        style={{ maxWidth: "750px" }}
                        objectFit="contain"
                      />
                    )}
                  </figure>
                )
              },
            },
          })}
        </div>
        {thankYouMessage ? (
          <div className="pb-10 w-1/2 flex justify-center m-auto">
            <h3 className="py-10 px-6 font-Orbitron text-center text-link">
              {thankYouMessage} Click here to view more{" "}
              <Link className="text-link" to="/resources/">
                resousurces
              </Link>
              .
            </h3>
          </div>
        ) : (
          <>
            {" "}
            <form
              action="https://mooremomentum.us20.list-manage.com/subscribe/post"
              method="POST"
              className="pb-10 md:w-1/2 w-full"
              onSubmit={handleSubmit} // Attach the submit handler
            >
              <input type="hidden" name="u" value="705d046da14d7bc6e462e6ec4" />
              <input type="hidden" name="id" value="fc1be8fe04" />
              <input type="hidden" name="tags" value="2633307" />
              {/* Hidden input for quiz tag */}

              <div className="mb-8 space-y-8">
                <div className="">
                  <div>
                    <input
                      className="appearance-none border-2 border-gray-dark px-4 py-3 rounded-md text-black w-full focus:border-blue"
                      id="MERGE1"
                      name="MERGE1"
                      placeholder="First Name"
                      required
                      type="text"
                      value={firstName}
                      onChange={e => setFirstName(e.currentTarget.value)}
                    />
                  </div>
                </div>
                <div>
                  <input
                    className="appearance-none border-2 border-gray-dark px-4 py-3 rounded-md text-black w-full focus:border-blue"
                    id="MERGE2"
                    name="MERGE2"
                    placeholder="Last Name"
                    required
                    type="text"
                    value={lastName}
                    onChange={e => setLastName(e.currentTarget.value)}
                  />
                </div>
                <div>
                  <input
                    className="appearance-none border-2 border-gray-dark px-4 py-3 rounded-md text-black w-full focus:border-blue"
                    id="MMERGE7"
                    name="MMERGE7"
                    placeholder="Last Name"
                    required
                    type="hidden"
                    value={data?.fileName.replace("/", "")}
                  />
                </div>
                <div>
                  <input
                    className="appearance-none border-2 border-gray-dark px-4 py-3 rounded-md text-black w-full focus:border-blue"
                    id="MERGE0"
                    name="MERGE0"
                    placeholder="Email Address"
                    required
                    type="email"
                    value={email}
                    onChange={e => setEmail(e.currentTarget.value)}
                  />
                </div>
              </div>

              <Button
                className="text-sm md:text-base m-auto flex"
                type="submit"
              >
                {loader ? "Downloading.." : "Download"}{" "}
              </Button>
            </form>
          </>
        )}
      </div>
    </Layout>
  )
}

export const query = graphql`
  query ($slug: String!) {
    contentfulResource(slug: { eq: $slug }) {
      description {
        raw
      }
      fileName
      id
      metaDescription
      slug
      title
      image {
        url
        gatsbyImageData(layout: CONSTRAINED)
        file {
          fileName
        }
      }
    }
  }
`

export default Resource
